import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logOutUser } from "../../actions";

const LogOutButton: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <button
      data-testid="logout-button"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={() => dispatch<any>(logOutUser())}
      type="button"
      className="button header-dropdown-button"
    >
      Log Out
    </button>
  );
};

const renderAccountSettingsLink = (
  closeHeaderDropdownComponent: () => void
) => {
  return (
    <button
      className="button header-dropdown-button"
      type="button"
      onClick={() => closeHeaderDropdownComponent()}
    >
      <Link
        to="/accountDetails"
        className="button header-dropdown-button header-dropdown-button-link"
      >
        Account Details
      </Link>
    </button>
  );
};

export interface IHeaderDropdownProps {
  headerButtonRef?: React.MutableRefObject<HTMLDivElement | null>;
  closeHeaderDropdownComponent: () => void;
}

const HeaderDropdown: React.FC<IHeaderDropdownProps> = ({
  closeHeaderDropdownComponent,
  headerButtonRef,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        headerButtonRef?.current &&
        !headerButtonRef.current.contains(event.target)
      )
        closeHeaderDropdownComponent();
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div ref={ref} className="header-dropdown-container">
      <div>
        <div className="header-dropdown-arrow" />
      </div>
      <div className="header-dropdown-items">
        {renderAccountSettingsLink(closeHeaderDropdownComponent)}
        <button
          className="button header-dropdown-button"
          type="button"
          onClick={() => closeHeaderDropdownComponent()}
        >
          <Link
            to="/alertPreferences"
            className="button header-dropdown-button header-dropdown-button-link"
          >
            Alert Preferences
          </Link>
        </button>
        <button
          className="button header-dropdown-button"
          type="button"
          onClick={() => closeHeaderDropdownComponent()}
        >
          <Link
            to="/managers"
            className="button header-dropdown-button header-dropdown-button-link"
          >
            Edit Managers
          </Link>
        </button>
        <LogOutButton />
      </div>
    </div>
  );
};

export default HeaderDropdown;
