import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import {
  brilliantUserToken,
  brilliantUserTokenWithLoader,
  logOutUser,
} from "../actions";
import { IAppState, ILoginData } from "../interfaces/interfaces";
import optimizely from "../OptimizelySetUp";
import { oneMinInMs } from "../utils/utils";

import { setNavigate } from "./navigation";
import Client from "./apollo/Client";
import {
  isAlertSideMenuVisibleVar,
  isSideMenuVisibleVar,
} from "./apollo/LocalState";
import Login from "./login/Login";
import Registration from "./registration/Registration";
import Spinner from "./Spinner";
import HeaderHelper from "./header/HeaderHelper";
import Footer from "./footer/Footer";
import Dashboard from "./dashboard/Dashboard";
import PropertyDetails from "./propertyDetails/PropertyDetails";
import UnitDetails from "./unitDetails/UnitDetails";
import Configure from "./configurations/Configure";
import ConfigurationsDetails from "./configurations/ConfigurationsDetails";
import ErrorsComponent, { NotFoundErrorComponent } from "./errors/Error";
import ToastMessages from "./ToastMessages";
import TenantRegistration from "./registration/tenantRegistration/TenantRegistration";
import AccountDetails from "./account/AccountDetails";
import EditManagers from "./adminManagement/EditManagers";
import AlertSideBar from "./alertManagement/AlertsSideBar";
import SideMenu from "./header/SideMenu";
import EntrataDetailsPage from "./integrations/EntrataDetailsPage";
import RealPageDetailsPage from "./integrations/RealPageDetailsPage";
import RemoteLock from "./integrations/RemoteLock";
import EntrataSuccess from "./login/entrata/EntrataSuccess";
import EntrataError from "./login/entrata/EntrataError";
import RemoteLockSuccess from "./integrations/RemoteLockSuccess";
import UserRegistration from "./registration/userRegistration/UserRegistration";
import InvitedUserRegistration from "./registration/userRegistration/InvitedUserRegistration";
import AlertPreferencesLandingPage from "./alertManagement/preferences/AlertPreferencesLandingPage";

const Layout: React.FC<{ dispatch: any; isAlertSideMenuVisible: boolean }> = ({
  dispatch,
  isAlertSideMenuVisible,
}) => (
  <>
    <ToastMessages />
    <IdleTimer
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onIdle={() => dispatch(logOutUser())}
      timeout={oneMinInMs * 30}
    />
    <HeaderHelper />
    <div className="app-body app-body-side-bar">
      <div className={`${isAlertSideMenuVisible && "app-body-hidden"}`}>
        <Outlet />
        {isAlertSideMenuVisible && <AlertSideBar />}
      </div>
    </div>
    <Footer />
  </>
);

const App: React.FC<{
  user: ILoginData;
  loading: boolean;
}> = (props) => {
  const { user, loading } = props;
  const dispatch = useDispatch();

  const isSideMenuVisible = useReactiveVar(isSideMenuVisibleVar);
  const isAlertSideMenuVisible = useReactiveVar(isAlertSideMenuVisibleVar);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(brilliantUserTokenWithLoader());
    setInterval(() => {
      dispatch(brilliantUserToken());
    }, oneMinInMs * 5);
  }, [dispatch, oneMinInMs]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  // Will replace setAttribute with actual color from backend.
  // const root = document.querySelector(":root") as Element;
  // root.setAttribute("style", "--app-color: red");

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        attributes: {
          customerEmail: user.email,
        },
        id: user.userId,
      }}
    >
      <ApolloProvider client={Client}>
        <div className="main-container">
          {loading && <Spinner />}
          <>
            {isSideMenuVisible && <SideMenu />}
            <Routes>
              <Route path="/entrata/link" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/register-tenant" element={<TenantRegistration />} />
              <Route
                path="/create-user-account"
                element={<UserRegistration />}
              />
              <Route
                path="/create-invited-user-account"
                element={<InvitedUserRegistration />}
              />
              <Route path="/entrata/success" element={<EntrataSuccess />} />
              <Route path="/entrata/error" element={<EntrataError />} />
              <Route
                path="/remotelock/success"
                element={<RemoteLockSuccess />}
              />

              {user.isAuthenticated ? (
                <>
                  <Route
                    element={
                      <Layout
                        dispatch={dispatch}
                        isAlertSideMenuVisible={isAlertSideMenuVisible}
                      />
                    }
                  >
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/configurations" element={<Configure />} />
                    <Route
                      path="/configurations/:configurationId"
                      element={<ConfigurationsDetails />}
                    />
                    <Route path="/entrata" element={<EntrataDetailsPage />} />
                    <Route
                      path="/alertPreferences"
                      element={<AlertPreferencesLandingPage />}
                    >
                      <Route
                        path="/alertPreferences/:buildingId"
                        element={<AlertPreferencesLandingPage />}
                      />
                    </Route>
                    <Route path="/realPage" element={<RealPageDetailsPage />} />
                    <Route path="/remoteLock" element={<RemoteLock />}>
                      <Route
                        path="/remoteLock/:buildingId"
                        element={<RemoteLock />}
                      />
                    </Route>
                    <Route
                      path="/buildings/:buildingId"
                      element={<PropertyDetails />}
                    />
                    <Route
                      path="/units/:propertyId"
                      element={<UnitDetails />}
                    />
                    <Route path="/managers" element={<EditManagers />} />
                    <Route
                      path="/accountDetails"
                      element={<AccountDetails />}
                    />
                    <Route
                      path="/errors/:status"
                      element={<ErrorsComponent />}
                    />
                    <Route path="*" element={<NotFoundErrorComponent />} />
                  </Route>
                </>
              ) : (
                <Route path="*" element={<Login />} />
              )}
            </Routes>
          </>
        </div>
      </ApolloProvider>
    </OptimizelyProvider>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);
