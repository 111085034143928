export const TOKEN_KEY = "token";

// constants for tenant registration
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const EMAIL_VERIFICATION_SENT = "EMAIL_VERIFICATION_SENT";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const VERIFICATION_CODE_FAILURE = "VERIFICATION_CODE_FAILURE";
export const VERIFICATION_RATE_LIMITED = "VERIFICATION_RATE_LIMITED";
export const EXPIRED_INVITATION = "EXPIRED_INVITATION";
export const FAMILY_NAME_INVALID = "FAMILY_NAME_INVALID";
export const GIVEN_NAME_INVALID = "GIVEN_NAME_INVALID";
export const BIRTHDATE_FORMAT_INVALID = "BIRTHDATE_FORMAT_INVALID";
export const BIRTHDATE_INVALID = "BIRTHDATE_INVALID";
export const PASSWORD_INVALID = "PASSWORD_INVALID";
export const INVITATION_NOT_FOUND = "INVITATION_NOT_FOUND";

export const GET_QUERY_PARAMS = "GET_QUERY_PARAMS";
export const CLEAR_REGISTRATION_ERROR = "CLEAR_REGISTRATION_ERROR";
export const PASSWORD_MISMATCH_REGISTRATION_ERROR =
  "PASSWORD_MISMATCH_REGISTRATION_ERROR";

// Constants for invites
export const INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION =
  "INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION";
export const CLEAR_INVITED_USER_ERROR = "CLEAR_INVITED_USER_ERROR";

export const DATE_CHANGE = "DATE_CHANGE";
export const TIME_CHANGE = "TIME_CHANGE";

// Constants for User registration
export const ADD_MFA_DEVICE_SUCCESS = "ADD_MFA_DEVICE_SUCCESS";
export const ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER";
export const ADD_USER_TO_ORGANIZATION_SUCCESS =
  "ADD_USER_TO_ORGANIZATION_SUCCESS";
export const PHONE_VERIFICATION_SENT = "PHONE_VERIFICATION_SENT";
export const PHONE_VERIFICATION_SUCCESS = "PHONE_VERIFICATION_SUCCESS";
export const USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION =
  "USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION";
export const TERMS_AND_CONDITIONS_ACCEPTED = "TERMS_AND_CONDITIONS_ACCEPTED";
