import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./sass/main.scss";
import { BrowserRouter } from "react-router-dom";

import App from "./components/App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./ErrorBoundaries";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

// TODO: if using Google Analytics replace react-ga library for react-ga-4 since react-ga is now archived and no longer actively maintained.
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "", {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

// history.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
