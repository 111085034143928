import ReactGA from "react-ga";
import { Dispatch } from "redux";
import { StatusCodes } from "http-status-codes";
import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse } from "axios";

import { IAppState, ILoginForm } from "../../interfaces/interfaces";
import { getToken, removeToken, setToken } from "../../utils/auth_token";
import {
  AUTHENTICATION_CODE_FAILURE,
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_USER_RATE_LIMITED,
  INVALID_TOKEN,
  LOADER_START,
  LOADER_STOP,
  LOGIN_USER_ERROR,
  LOGIN_USER_FAILURE,
  LOGIN_USER_FORBIDDEN,
  LOGIN_USER_RATE_LIMITED,
  LOGIN_USER_SUCCESS,
  REMOVE_LOGIN_ERROR_ON_CHANGE,
  SAVE_EMAIL_ADDRESS,
  SAVE_USER_ID,
  USER_SIGNED_OUT,
} from "../types";
import EWebAPIError from "../../api/errors";
import { brilliantUrl } from "../../api/api";

export const loginUser =
  (values: ILoginForm) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    try {
      /* eslint-disable camelcase */
      const response: AxiosResponse<{ mfa_value: string; token: string }> =
        await brilliantUrl.post("/login/admin", {
          email_address: values.emailField,
          password: values.passwordField,
        });
      dispatch({
        payload: {
          email: values.emailField,
          mfaToken: response.data.token,
          phoneNumber: response.data.mfa_value,
        },
        type: LOGIN_USER_SUCCESS,
      });
      dispatch({
        type: LOADER_STOP,
      });
    } catch (error) {
      dispatch({
        type: LOADER_STOP,
      });
      const { response } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.WRONG_CREDENTIALS
      ) {
        dispatch({
          type: LOGIN_USER_FAILURE,
        });
      } else if (
        response &&
        response.status === StatusCodes.TOO_MANY_REQUESTS &&
        response.data.error_id === EWebAPIError.TOO_MANY_REQUESTS
      ) {
        dispatch({
          type: LOGIN_USER_RATE_LIMITED,
        });
      } else if (
        response &&
        response.status === StatusCodes.FORBIDDEN &&
        response.data.error_id === EWebAPIError.UNAUTHORIZED_LOGIN
      ) {
        dispatch({
          type: LOGIN_USER_FORBIDDEN,
        });
      } else {
        dispatch({
          type: LOGIN_USER_ERROR,
        });
      }
    }
  };

export const removeLoginError = (
  dispatch: Dispatch,
  getState: () => IAppState
): void => {
  if (getState().user.error) {
    dispatch({
      type: REMOVE_LOGIN_ERROR_ON_CHANGE,
    });
  }
};

export const authenticateUser =
  (code: string) =>
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    try {
      const response: AxiosResponse<{
        user_id: string;
        organization_id: string;
        token: string;
        email_address: string;
      }> = await brilliantUrl.post("/login/admin/verify-mfa-code", {
        code,
        email_address: getState().user.email,
        token: getState().user.mfaToken,
      });
      dispatch({
        // We need to add this action to write userId into redux store before isAuthenticated state is set
        payload: {
          userId: response.data.user_id,
        },
        type: SAVE_USER_ID,
      });
      setToken(response.data.token);
      dispatch({
        payload: {
          organizationId: response.data.organization_id,
          userId: response.data.user_id,
        },
        type: AUTHENTICATION_SUCCESS,
      });
      ReactGA.set({ userId: response.data.user_id });
      Sentry.setUser({
        email: response.data.email_address,
        id: response.data.user_id,
      });

      dispatch({
        type: LOADER_STOP,
      });
    } catch (error) {
      dispatch({
        type: LOADER_STOP,
      });
      const { response } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({
          type: INVALID_TOKEN,
        });
      } else if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.WRONG_CODE
      ) {
        dispatch({
          type: AUTHENTICATION_CODE_FAILURE,
        });
      } else if (
        response &&
        response.status === StatusCodes.TOO_MANY_REQUESTS &&
        response.data.error_id === EWebAPIError.TOO_MANY_REQUESTS
      ) {
        dispatch({
          type: AUTHENTICATION_USER_RATE_LIMITED,
        });
      } else {
        dispatch({
          type: AUTHENTICATION_FAILURE,
        });
      }
    }
  };

export const brilliantUserToken =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const currentToken = getToken();
    if (currentToken) {
      try {
        const response: AxiosResponse<{
          user_id: string;
          organization_id: string;
          token: string;
          email_address: string;
        }> = await brilliantUrl.post(
          "/login/admin/refresh",
          {},
          {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          }
        );
        setToken(response.data.token);
        dispatch({
          type: LOADER_STOP,
        });
        dispatch({
          // We need to add this action to write userId into redux store before isAuthenticated state is set
          payload: {
            userId: response.data.user_id,
          },
          type: SAVE_USER_ID,
        });
        dispatch({
          payload: {
            email: response.data.email_address,
          },
          type: SAVE_EMAIL_ADDRESS,
        });
        dispatch({
          payload: {
            organizationId: response.data.organization_id,
            userId: response.data.user_id,
          },
          type: AUTHENTICATION_SUCCESS,
        });
        ReactGA.set({ userId: response.data.user_id });
        Sentry.setUser({
          email: response.data.email_address,
          id: response.data.user_id,
        });
      } catch (error) {
        dispatch({
          type: LOADER_STOP,
        });
        removeToken();
        dispatch({
          type: USER_SIGNED_OUT,
        });
      }
    } else {
      dispatch({
        type: USER_SIGNED_OUT,
      });
    }
  };

export const brilliantUserTokenWithLoader =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>): Promise<void> => {
    const currentToken = getToken();
    if (currentToken) {
      dispatch({
        type: LOADER_START,
      });
      await dispatch(brilliantUserToken());
    }
  };
