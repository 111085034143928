import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dispatch } from "redux";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";

import {
  addPhoneNumber,
  clearRegistrationError,
  createUser,
  passwordMismatchError,
} from "../../actions";
import {
  ERegistrationError,
  IAppState,
  IRegistrationAccountFormData,
} from "../../interfaces/interfaces";
import {
  errorMessage,
  maxLength1000,
  minLength8,
  normalizePhone,
  phoneNumberLength,
  required,
} from "../../formValidation/FormValidation";
import { ReactComponent as HidePassword } from "../../img/HidePassword.svg";
import { ReactComponent as ShowPassword } from "../../img/ShowPassword.svg";
import SubmitButton from "../buttons/SubmitButton";
import { renderTermsAndConditionsAndPrivacyPolicy } from "../../utils/utils";

import InputField from "./common/InputField";

interface ICompleteAccountFormCustomProps {
  registrationError: ERegistrationError;
  userExists: boolean;
  birthdate: string;
}

let gSelectedDate = "";

const CompleteAccountForm: React.FC<
  ICompleteAccountFormCustomProps &
    InjectedFormProps<
      IRegistrationAccountFormData,
      ICompleteAccountFormCustomProps
    >
> = ({
  userExists,
  handleSubmit,
  invalid,
  submitting,
  pristine,
  registrationError,
  birthdate,
}) => {
  const dispatch = useDispatch();
  const clearError = () => dispatch(clearRegistrationError);

  const [selectedDate, setDate] = useState<any>(null);
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  useEffect(() => {
    if (userExists) {
      setDate(dayjs(birthdate));
    }
    gSelectedDate = birthdate;
  }, [birthdate, userExists]);
  const showError = registrationError !== ERegistrationError.noError;
  return (
    <form onSubmit={handleSubmit} className="right-form">
      <div className="right-form-row">
        <div className="right-form-field">
          <label htmlFor="givenNameField">First Name</label>
          <Field
            disabled={userExists}
            id="givenNameField"
            name="givenName"
            registrationErrorHighlight={[ERegistrationError.givenNameInvalid]}
            validate={[required, maxLength1000]}
            component={InputField}
            type="input"
            onChange={clearError}
          />
        </div>

        <div className="right-form-field">
          <label htmlFor="familyNameField">Last Name</label>
          <Field
            disabled={userExists}
            id="familyNameField"
            name="familyName"
            registrationErrorHighlight={[ERegistrationError.familyNameInvalid]}
            validate={[required, maxLength1000]}
            component={InputField}
            type="input"
            onChange={clearError}
          />
        </div>
      </div>

      <label htmlFor="emailAddressField">Email</label>
      <Field
        disabled
        id="emailAddressField"
        name="emailAddress"
        validate={[required]}
        component={InputField}
        type="input"
      />

      <label htmlFor="phoneNumberField">Mobile Number</label>
      <Field
        id="phoneNumberField"
        name="phoneNumber"
        normalize={normalizePhone}
        placeholder="(111) 111-1111"
        validate={[required, phoneNumberLength, maxLength1000]}
        component={InputField}
        type="input"
        onChange={clearError}
      />

      <label htmlFor="organizationNameField">Organization</label>
      <Field
        disabled
        id="organizationNameField"
        name="organizationName"
        validate={[required]}
        component={InputField}
        type="input"
        onChange={clearError}
      />

      <label htmlFor="birthdateField">Birthdate</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={userExists}
          value={selectedDate}
          minDate={dayjs("1900-01-01")}
          maxDate={dayjs()}
          views={["year", "month", "day"]}
          openTo="year"
          onChange={(date) => {
            setDate(date);
            if (date !== null) {
              gSelectedDate = `${
                date.month() + 1
              }/${date.date()}/${date.year()}`;
            } else {
              gSelectedDate = "";
            }
          }}
        />
      </LocalizationProvider>

      {!userExists && (
        <div className="right-form-field-passwords">
          <div className="right-form-field-password-container right-form-field-password-container-left">
            <label htmlFor="newPasswordField">New Password</label>
            <div className="right-form-field-password">
              <Field
                id="newPasswordField"
                name="newPassword"
                autoComplete="new-password"
                registrationErrorHighlight={[
                  ERegistrationError.passwordInvalid,
                  ERegistrationError.passwordMismatch,
                ]}
                validate={[required, minLength8, maxLength1000]}
                component={InputField}
                type={newPasswordType}
                onChange={clearError}
              />
              <div className="right-form-field-password-icon">
                {newPasswordType === "text" ? (
                  <HidePassword
                    onClick={() => setNewPasswordType("password")}
                  />
                ) : (
                  <ShowPassword onClick={() => setNewPasswordType("text")} />
                )}
              </div>
            </div>
          </div>
          <div className="right-form-field-password-container">
            <label htmlFor="confirmPasswordField">Confirm Password</label>
            <div className="right-form-field-password">
              <Field
                id="confirmPasswordField"
                name="confirmPassword"
                autoComplete="new-password"
                registrationErrorHighlight={[
                  ERegistrationError.passwordInvalid,
                  ERegistrationError.passwordMismatch,
                ]}
                validate={[required, minLength8, maxLength1000]}
                component={InputField}
                type={confirmPasswordType}
                onChange={clearError}
              />
              <div className="right-form-field-password-icon">
                {confirmPasswordType === "text" ? (
                  <HidePassword
                    onClick={() => setConfirmPasswordType("password")}
                  />
                ) : (
                  <ShowPassword
                    onClick={() => setConfirmPasswordType("text")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showError && (
        <p className="right-error" id="error-message">
          {errorMessage[ERegistrationError[registrationError]]}
        </p>
      )}

      {renderTermsAndConditionsAndPrivacyPolicy()}

      <SubmitButton
        disabled={invalid || submitting || pristine || gSelectedDate === ""}
        name="Complete Account"
        decorator="right-form-btn"
      />
    </form>
  );
};

const onSubmit = (
  values: IRegistrationAccountFormData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  if (values.newPassword === values.confirmPassword) {
    values.birthdate = gSelectedDate;
    dispatch(addPhoneNumber(values.phoneNumber));

    // Update: Do not add user to organization yet. Wait until the phone number has been verified.
    if (!values.userId) dispatch(createUser(values));
  } else {
    dispatch(passwordMismatchError);
  }
};

const ReduxForm = reduxForm<
  IRegistrationAccountFormData,
  ICompleteAccountFormCustomProps
>({
  enableReinitialize: true,
  form: "completeAccount",
  onSubmit,
})(CompleteAccountForm);

const mapStateToProps = (state: IAppState) => ({
  birthdate: state.newUser.birthdate,
  initialValues: {
    birthdate: state.newUser.birthdate,
    emailAddress: state.newUser.email,
    familyName: state.newUser.familyName,
    givenName: state.newUser.givenName,
    organizationName: state.newUser.organizationName,
    userId: state.newUser.userId,
  },
  registrationError: state.newUser.error,
  userExists: state.newUser.userId.length !== 0,
});

export default connect(mapStateToProps)(ReduxForm);
