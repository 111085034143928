import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  ERegistrationError,
  IAppState,
  IUserRegistrationData,
} from "../../../interfaces/interfaces";
import ExpiredInvitation from "../ExpiredInvitation";
import { getQueryParams, sendUserVerificationEmail } from "../../../actions";
import InvalidInvitation from "../InvalidInvitation";
import SuccessPage from "../common/SuccessPage";
import EmailVerificationStep from "../common/EmailVerificationStep";
import CompleteAccountStep from "../common/CompleteAccountStep";
import UserRegistrationError from "../../errors/UserRegistrationError";

import EmailVerificationForm from "./EmailVerificationForm";
import CompleteAccountForm from "./CompleteAccountForm";

const TenantRegistration: React.FC<{ newTenant: IUserRegistrationData }> = ({
  newTenant,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(getQueryParams(location));
    dispatch(sendUserVerificationEmail({ isTenantRegistrationFlow: true }));
  }, [dispatch]);

  const { buildingName, propertyName } = newTenant;

  if (newTenant.error === ERegistrationError.expiredInvitation)
    return <ExpiredInvitation />;
  if (newTenant.error === ERegistrationError.invalidInvitation)
    return <InvalidInvitation />;

  if (!newTenant.emailVerified)
    return (
      <EmailVerificationStep
        isUser
        form={<EmailVerificationForm />}
        email={newTenant.email}
      />
    );
  // The CompleteAccountStep may show for an existing user before the SuccessPage is displayed.
  // To prevent this, we check whether the tenant has a user ID (i.e. is an existing user) before
  // showing the CompleteAccountStep.
  if (!newTenant.accountCompleted && !newTenant.userId)
    return <CompleteAccountStep form={<CompleteAccountForm />} isUser />;
  if (newTenant.accountCompleted)
    return (
      <SuccessPage shouldTipsOnGettingStartedRender>
        <p>
          You&apos;ve successfully joined <b>{propertyName}</b> in{" "}
          <b>{buildingName}</b>.
        </p>
        <br />
        <p>
          The Brilliant Devices in <b>{propertyName}</b> are now being setup for
          your own personal use. This process should take around 5 minutes.
        </p>
        <br />
        <p>
          Tap below to install the Brilliant Mobile App to get started using
          your Brilliant Devices.
        </p>
      </SuccessPage>
    );
  return (
    <UserRegistrationError
      description="There was a problem joining your unit's Brilliant Home. If the problem persists, please contact Brilliant."
      name="Something went wrong"
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    newTenant: state.newUserRegistration,
  };
};

export default connect(mapStateToProps)(TenantRegistration);
