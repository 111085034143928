import { useLocation, useNavigate } from "react-router-dom";

import { IPropertyData } from "../interfaces/interfaces";

const BuildingDropdown: React.FC<{
  sortedBuildings: IPropertyData[];
  setSelectedBuildingId: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  selectedBuildingId: string;
}> = ({
  sortedBuildings,
  setSelectedBuildingId,
  selectedBuildingId,
  disabled,
}) => {
  const renderSelectOptions = (building: IPropertyData) => (
    <option
      className="scheduler-select-option menu"
      key={building.id}
      value={building.id}
    >
      {building.propertyName}
    </option>
  );
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="building-select-container network-type-select-container">
        <select
          onChange={(e) => {
            setSelectedBuildingId(e.target.value);
            navigate(location.pathname);
          }}
          disabled={disabled}
          className="building-select menu tool-tip"
          value={selectedBuildingId}
        >
          {sortedBuildings.map(renderSelectOptions)}
        </select>
      </div>
    </>
  );
};

export default BuildingDropdown;
