import axios, { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/browser";
import { Dispatch } from "redux";
import { StatusCodes } from "http-status-codes";

import { checkToken, getToken } from "../utils/auth_token";

import EWebAPIError from "./errors";

export const brilliantUrl = axios.create({
  baseURL: process.env.REACT_APP_BRILLIANT_URL,
});

// onError should return "true" if it has handled the error and no further action is required.
// onError should return "false" if the error was not handled.
const brilliantRequestHelper = async (
  responsePromise: (headers: {
    headers: {
      Authorization: string;
    };
  }) => Promise<AxiosResponse>,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  /* eslint-disable camelcase */
  onError: (error: AxiosError<{ error_id: EWebAPIError }>) => boolean
) => {
  const token = getToken();
  checkToken(dispatch, token);
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const response = await responsePromise(headers);
    onSuccess(response);
  } catch (error) {
    const axiosError = error as AxiosError<{ error_id: EWebAPIError }>;
    const { response, message } = error as AxiosError;
    if (!onError(axiosError)) {
      if (
        response?.status === StatusCodes.INTERNAL_SERVER_ERROR ||
        response?.status === StatusCodes.NOT_FOUND
      ) {
        Sentry.captureMessage(
          `${response.config.url} API returned ${
            response.statusText
          } error: ${JSON.stringify((response && response.data) || message)}`
        );
      }
      // TODO: Pass navigate in here if current method looks strange on the UI.
      window.location.assign(`/error/${response?.status}`);
    }
  }
};

export async function brilliantRequest(
  path: string,
  params: Record<string, unknown>,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  /* eslint-disable camelcase */
  onError: (error: AxiosError<{ error_id: EWebAPIError }>) => boolean
): Promise<void> {
  const postPromise = (headers: {
    headers: {
      Authorization: string;
    };
  }) => {
    return brilliantUrl.post(path, params, headers);
  };
  await brilliantRequestHelper(postPromise, dispatch, onSuccess, onError);
}

export async function brilliantRequestGet(
  path: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): Promise<void> {
  const getPromise = (headers: {
    headers: {
      Authorization: string;
    };
  }) => {
    return brilliantUrl.get(path, headers);
  };
  await brilliantRequestHelper(getPromise, dispatch, onSuccess, onError);
}

export async function brilliantRequestDelete(
  path: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean,
  params?: Record<string, unknown>
): Promise<void> {
  const deletePromise = (header: {
    headers: {
      Authorization: string;
    };
  }) => {
    const { headers } = header;
    return brilliantUrl.delete(path, {
      data: params,
      headers,
    });
  };
  await brilliantRequestHelper(deletePromise, dispatch, onSuccess, onError);
}
