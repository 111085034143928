import { NavigateFunction } from "react-router-dom";

let navigate: NavigateFunction | null = null;

export const setNavigate = (navigateFunction: any) => {
  navigate = navigateFunction;
};

export const navigateTo = (path: string) => {
  if (navigate) {
    navigate(path); // Navigate after async tasks complete
  } else {
    // eslint-disable-next-line
    console.warn(
      "Navigate function not set. Make sure to call setNavigate first."
    );
  }
};
