import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_SMALL_HEADER, isSideMenuVisibleVar } from "../apollo/LocalState";
import { ReactComponent as Arrow } from "../../img/Arrow.svg";
import { ReactComponent as Hamburger } from "../../img/header/Hamburger.svg";
import { ReactComponent as Logo } from "../../img/logos/Logo.svg";

import AlertBell from "./AlertBell";

const renderCentralHeader = (title: string, description: string) => {
  if (title === "") {
    return <Logo title="logo" />;
  }
  return (
    <div className="center-align-as-column">
      <div data-testid="small-header-title" className="menu">
        {title}
      </div>
      <div
        data-testid="small-header-description"
        className="unit-description unit-description-small"
      >
        {description}
      </div>
    </div>
  );
};

const HeaderSmall: React.FC = () => {
  const { data } = useQuery(GET_SMALL_HEADER);
  const navigate = useNavigate();
  return (
    <div className="header-container header-container-small">
      {data.smallHeaderBackArrow ? (
        <Arrow
          title="Back"
          data-testid="small-header-back-arrow"
          className="header-back"
          onClick={() => {
            navigate(-1);
          }}
        />
      ) : (
        <Hamburger
          title="menu"
          onClick={() => {
            isSideMenuVisibleVar(true);
          }}
        />
      )}
      {renderCentralHeader(data.smallHeaderTitle, data.smallHeaderDescription)}

      <AlertBell />
    </div>
  );
};

export default HeaderSmall;
