import * as Sentry from "@sentry/browser";
import {
  ApolloClient,
  InMemoryCache,
  Operation,
  Reference,
  StoreObject,
  createHttpLink,
  gql,
} from "@apollo/client";
import { Observable } from "@apollo/client/utilities";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { RetryLink } from "@apollo/client/link/retry";
import { StatusCodes } from "http-status-codes";
import lodash from "lodash";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import optimizely from "../../OptimizelySetUp";
import { oneMinInMs, oneSecInMs } from "../../utils/utils";
import { checkToken, getToken } from "../../utils/auth_token";
import { EWarningTypes, IWarning } from "../../interfaces/interfaces";
import { AlertTypes, alertTitle } from "../../utils/alertUtils";
import {
  ADD_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL,
  GET_INTEGRATIONS_FOR_ORGANIZATION_GQL,
  GET_REMOTELOCK_BUILDING_GQL,
  REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL,
  UPDATE_CARD_CREDENTIALS_SQL,
  UPDATE_USER_NOTIFICATION_SETTINGS_SQL_MUTATION,
} from "../../api/gqlQueries";
import store from "../../store";
import { navigateTo } from "../navigation";

import {
  conflictUnitsVar,
  isRemoteLockPolling,
  isRemoteLockTokenInvalidVar,
  isResendInvitationErrorVar,
  reconfigurationErrorVar,
  smallHeaderBackArrow,
  smallHeaderDescription,
  smallHeaderTitle,
} from "./LocalState";

const getHomeRef = (homeId: string) => {
  return {
    __ref: `Home:${homeId}`,
  };
};

const isRetrySQLOperation = (operation: Operation) => {
  return [
    ADD_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL,
    REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES_GQL,
    GET_REMOTELOCK_BUILDING_GQL,
    GET_INTEGRATIONS_FOR_ORGANIZATION_GQL,
    UPDATE_USER_NOTIFICATION_SETTINGS_SQL_MUTATION,
  ].includes(operation.operationName);
};

// Used to check if operation needs to retry. Also used to not navigate to error page.
const isRetryOperation = (status: any, operation: Operation) => {
  return (
    (status === StatusCodes.TOO_MANY_REQUESTS ||
      status === StatusCodes.SERVICE_UNAVAILABLE) &&
    isRetrySQLOperation(operation)
  );
};

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      if ("statusCode" in error) {
        const status = error.statusCode;
        return isRetryOperation(status, operation);
      }
      return false;
    },
  },
  delay: {
    initial: oneSecInMs * 10,
    max: oneMinInMs,
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BRILLIANT_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  // return the headers to the context so httpLink can read them
  checkToken(store.dispatch, token);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const navigateHelper = async (path: string) => {
  await navigateTo(path);
};

const logError = onError(({ networkError, graphQLErrors, operation }) => {
  return new Observable((observer) => {
    const handleErrors = async () => {
      // Handle GraphQL errors
      if (networkError) {
        if ("statusCode" in networkError) {
          const status = networkError.statusCode;
          if (
            status === StatusCodes.UNAUTHORIZED &&
            isRetrySQLOperation(operation)
          ) {
            isRemoteLockTokenInvalidVar(true);
            await navigateHelper("/configurations");
          } else if (isRetryOperation(status, operation)) {
            return;
          } else {
            if (
              status === StatusCodes.INTERNAL_SERVER_ERROR ||
              status === StatusCodes.NOT_FOUND
            ) {
              Sentry.captureMessage(
                `/graphql API returned ${
                  networkError.name
                } networkError: ${JSON.stringify(networkError.message)}`
              );
            }
            if (
              (status === StatusCodes.TOO_MANY_REQUESTS ||
                status === StatusCodes.BAD_REQUEST) &&
              UPDATE_CARD_CREDENTIALS_SQL
            ) {
              return;
            }
            await navigateHelper(`/errors/${status}`);
          }
        }
      }
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          return Sentry.captureMessage(
            `/graphql API returned graphQLError: ${JSON.stringify(message)}`
          );
        });
        await navigateHelper("/errors/500");
      }

      observer.complete();
    };
    handleErrors();
  });
});

export const AlertFragment = gql`
  fragment AlertFragment on Alert {
    id
    home {
      id
    }
  }
`;

export const numBrilliantControlsFragment = gql`
  fragment numBrilliantControlsFragment on Home {
    numBrilliantControls
  }
`;

export const numBrilliantSwitchesFragment = gql`
  fragment numBrilliantSwitchesFragment on Home {
    numBrilliantSwitches
  }
`;

export const numBrilliantPlugsFragment = gql`
  fragment numBrilliantPlugsFragment on Home {
    numBrilliantPlugs
  }
`;

export const numIntegrationsFragment = gql`
  fragment numIntegrationsFragment on Home {
    numIntegrations
  }
`;

export const warningsFragment = gql`
  fragment warningsFragment on Home {
    warnings {
      warningType
    }
  }
`;

interface refProps {
  __ref: string;
}

interface FragmentWithHomeProps {
  __typename: string;
  home: Reference | StoreObject;
  id: string;
}

// Filter out unknown alerts.
const getOnlyKnownAlerts = (
  incoming: Array<refProps>,
  readField: ReadFieldFunction
) => {
  const isOfflineBrilliantControlAlertEnabled = optimizely.isFeatureEnabled(
    "offline_brilliant_control_alert"
  );
  return incoming.filter((climateAlarm: Reference) => {
    const alarmType = readField<string>("alarmType", climateAlarm);
    if (alarmType === AlertTypes.LowBattery) {
      return false;
    }
    if (
      alarmType === AlertTypes.OfflineDevices &&
      !isOfflineBrilliantControlAlertEnabled
    ) {
      return false;
    }
    return alarmType !== undefined && alertTitle[alarmType] !== undefined;
  });
};

// Group Alerts by Home IDs.
const groupAlertsByHomeCacheID = (
  filteredAlerts: Array<refProps>,
  cache: InMemoryCache
) => {
  return lodash.groupBy(filteredAlerts, (alert) => {
    const fragment: FragmentWithHomeProps | null = cache.readFragment({
      fragment: AlertFragment,
      id: cache.identify(alert),
    });
    if (fragment === null) {
      return "";
    }
    return cache.identify(fragment.home);
  });
};

// The Home's alerts are a subset of the Organization's alerts.
// Thus, replace the Home's alerts with corresponding entries in the Organization's alerts.
const replaceHomeClimateAlerts = (
  alertsByHomeCacheId: lodash.Dictionary<refProps[]>,
  cache: InMemoryCache
) => {
  lodash.forEach(alertsByHomeCacheId, (alerts, homeCacheId: string) => {
    cache.writeFragment({
      data: {
        alerts,
      },
      fragment: gql`
        fragment UpdateHomeAlerts on Home {
          alerts
        }
      `,
      id: homeCacheId,
    });
  });
};

// Clear the alerts that have stopped occurring from homes
// that may have cached the old alarms.
const removeResolvedClimateAlertsFromHomes = (
  existing: [],
  cache: InMemoryCache,
  alertsByHomeCacheId: lodash.Dictionary<refProps[]>
) => {
  const homeIdKeys = lodash.keys(alertsByHomeCacheId);
  existing.forEach((alert: Reference) => {
    const fragment: FragmentWithHomeProps | null = cache.readFragment({
      fragment: AlertFragment,
      id: cache.identify(alert),
    });
    if (fragment === null) {
      return "";
    }
    const homeRef = cache.identify(fragment.home);
    if (homeRef !== undefined) {
      if (!homeIdKeys.includes(homeRef)) {
        cache.writeFragment({
          data: {
            alerts: [],
          },
          fragment: gql`
            fragment UpdateHomeAlerts on Home {
              alerts
            }
          `,
          id: cache.identify(fragment.home),
        });
      }
    }
    return "";
  });
};

const isOfflineDevicesAlertPresent = (
  alerts: any,
  readField: ReadFieldFunction
) => {
  let alertsHasOfflineDevices = false;
  if (alerts) {
    alertsHasOfflineDevices = alerts.find((climateAlarm: Reference) => {
      const alarmType = readField<string>("alarmType", climateAlarm);
      return alarmType === AlertTypes.OfflineDevices;
    });
  }
  return alertsHasOfflineDevices;
};

export const apolloCache = new InMemoryCache({
  possibleTypes: {
    Alert: [
      "LeakAlert",
      "ExtremeTemperatureClimateAlarm",
      "InvitationFailedAlert",
      "ReconfigurationTakingTooLongAlert",
      "LowBatteryAlert",
      "OfflineDevicesAlert",
    ],
  },
  typePolicies: {
    Home: {
      fields: {
        alerts: {
          // If the actual value of alerts is not available we assign an empty string
          // Otherwise, return the cached value of alerts
          read(alerts = []) {
            return alerts;
          },
        },
        brilliantControls: {
          merge(_, incoming, { variables, cache }) {
            cache.writeFragment({
              data: {
                numBrilliantControls: incoming.length,
              },
              fragment: numBrilliantControlsFragment,
              id: cache.identify(getHomeRef(variables?.propertyId)),
            });
            return incoming;
          },
        },
        brilliantPlugs: {
          merge(_, incoming, { variables, cache }) {
            cache.writeFragment({
              data: {
                numBrilliantPlugs: incoming.length,
              },
              fragment: numBrilliantPlugsFragment,
              id: cache.identify(getHomeRef(variables?.propertyId)),
            });
            return incoming;
          },
        },
        brilliantSwitches: {
          merge(_, incoming, { variables, cache }) {
            cache.writeFragment({
              data: {
                numBrilliantSwitches: incoming.length,
              },
              fragment: numBrilliantSwitchesFragment,
              id: cache.identify(getHomeRef(variables?.propertyId)),
            });
            return incoming;
          },
        },
        integrations: {
          merge(_, incoming, { variables, cache }) {
            cache.writeFragment({
              data: {
                numIntegrations: incoming.length,
              },
              fragment: numIntegrationsFragment,
              id: cache.identify(getHomeRef(variables?.propertyId)),
            });
            return incoming;
          },
        },
        warnings: {
          read(warnings = [], { readField }) {
            const isOfflineBrilliantControlAlertEnabled =
              optimizely.isFeatureEnabled("offline_brilliant_control_alert");
            const numOfflineControlsField =
              readField<number>("numOfflineDevices");
            const isInstallIncompleteField = readField<boolean>(
              "isInstallIncomplete"
            );
            const alerts = readField<Array<refProps>>("alerts");
            const alertsHasOfflineDevices =
              isOfflineBrilliantControlAlertEnabled &&
              isOfflineDevicesAlertPresent(alerts, readField);

            const shouldOfflineDevicesBeWritten =
              Boolean(numOfflineControlsField && numOfflineControlsField > 0) &&
              !alertsHasOfflineDevices;
            if (
              isInstallIncompleteField ||
              (numOfflineControlsField && numOfflineControlsField > 0)
            ) {
              const warningsMap = new Map<EWarningTypes, IWarning>();
              if (isInstallIncompleteField) {
                warningsMap.set(EWarningTypes.InstallIncomplete, {
                  warningType: EWarningTypes.InstallIncomplete,
                });
              }
              if (shouldOfflineDevicesBeWritten) {
                warningsMap.set(EWarningTypes.OfflineDevices, {
                  warningType: EWarningTypes.OfflineDevices,
                });
              }
              // TODO: Write to cache in parallel
              // cache.writeFragment({
              //   data: {
              //     __typename: "Home",
              //     warnings: Array.from(warningsMap.values()),
              //   },
              //   fragment: warningsFragment,
              //   id: cache.identify(getHomeRef(variables?.propertyId)),
              // });
              return Array.from(warningsMap.values());
            }
            return warnings;
          },
        },
      },
    },
    Organization: {
      fields: {
        alerts: {
          merge(existing = [], incoming: [], { readField, cache }) {
            const filteredAlerts = getOnlyKnownAlerts(incoming, readField);
            console.log("filteredAlerts", filteredAlerts);
            const alertsByHomeCacheId = groupAlertsByHomeCacheID(
              filteredAlerts,
              cache
            );
            replaceHomeClimateAlerts(alertsByHomeCacheId, cache);
            removeResolvedClimateAlertsFromHomes(
              existing,
              cache,
              alertsByHomeCacheId
            );
            return filteredAlerts;
          },
        },
      },
    },
    Query: {
      fields: {
        conflictUnitsVar: {
          read() {
            return conflictUnitsVar();
          },
        },
        isRemoteLockPolling: {
          read() {
            return isRemoteLockPolling();
          },
        },
        isRemoteLockTokenInvalidVar: {
          read() {
            return isRemoteLockTokenInvalidVar();
          },
        },
        isResendInvitationErrorVar: {
          read() {
            return isResendInvitationErrorVar();
          },
        },
        reconfigurationErrorVar: {
          read() {
            return reconfigurationErrorVar();
          },
        },
        smallHeaderBackArrow: {
          read() {
            return smallHeaderBackArrow();
          },
        },
        smallHeaderDescription: {
          read() {
            return smallHeaderDescription();
          },
        },
        smallHeaderTitle: {
          read() {
            return smallHeaderTitle();
          },
        },
      },
    },
  },
});

export default new ApolloClient({
  cache: apolloCache,
  link: retryLink.concat(logError.concat(authLink.concat(httpLink))),
});
