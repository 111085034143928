import { Link } from "react-router-dom";

import { quantityDisplay, renderSideLine, renderTags } from "../../utils/utils";
import Arrow from "../../img/Arrow.svg";
import { IPropertyUnitData } from "../../interfaces/interfaces";
import { isReconfigurationInProgress } from "../../utils/reconfigurationUtils";

const renderTenants = ({
  numInvitedResidents,
  numTenants,
}: {
  numInvitedResidents: number;
  numTenants: number;
}) => {
  const totalResidents = numTenants + numInvitedResidents;
  return (
    <div className="flex-display">
      {totalResidents !== 0 && (
        <>
          <div className="margin-right-very-small">/</div>
          <div className="margin-right-very-small">{totalResidents}</div>
          <div className="margin-right-very-small">
            {quantityDisplay(totalResidents, "Resident")}
          </div>
        </>
      )}
    </div>
  );
};

const UnitCard: React.FC<IPropertyUnitData> = ({
  alerts,
  propertyId,
  name,
  isHubless,
  numBrilliants,
  numThirdpartyIntegrations,
  numTenants,
  numInvitedResidents,
  reconfigurationState,
  warnings,
}) => {
  const hasAlerts = alerts.length > 0;
  const hasWarnings = warnings && warnings.length > 0;
  const isOccupied = numTenants > 0;
  const isInReconfig = isReconfigurationInProgress(reconfigurationState);
  const renderDescription = isInReconfig ? (
    <div className="unit-devices unit-description-small">
      <div className="margin-right-very-small">
        <i> RECONFIGURING...</i>
      </div>
    </div>
  ) : (
    <div className="unit-devices unit-description-small">
      {!isHubless && (
        <>
          <div className="margin-right-very-small">{numBrilliants}</div>
          <div className="margin-right-very-small">
            {quantityDisplay(numBrilliants, "BRILLIANT")}
          </div>
          <div className="margin-right-very-small">/</div>
        </>
      )}
      <div className="margin-right-very-small">{numThirdpartyIntegrations}</div>
      <div className="margin-right-very-small">
        {quantityDisplay(numThirdpartyIntegrations, "Integration")}
      </div>
      {renderTenants({
        numInvitedResidents,
        numTenants,
      })}
    </div>
  );

  const vacancyTagClass = `unit-status unit-tag ${
    isOccupied ? "unit-status-occupied" : "unit-status-vacant"
  }`;
  return (
    <div className="unit-container">
      {renderSideLine({
        hasAlerts,
        hasWarnings,
        lineClassName: "unit-line",
      })}
      <Link
        to={`/units/${propertyId}`}
        className="unit-link"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <div className="unit-card-description-container">
          <div className="flex-with-space-between">
            <div className="unit-card-description">
              <div className="unit-title unit-name">{name}</div>
              {renderDescription}
            </div>
            <img
              src={Arrow}
              alt="arrow"
              className="unit-card-description-arrow-svg"
            />
          </div>
        </div>
      </Link>
      <div className="center-align-as-row">
        <div className={vacancyTagClass}>
          {isOccupied ? "Occupied" : "Vacant"}
        </div>
        {renderTags({ alerts, warnings })}
      </div>
    </div>
  );
};

export default UnitCard;
