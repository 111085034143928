import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  ERegistrationError,
  IAppState,
  IUserRegistrationData,
} from "../../../interfaces/interfaces";
import ExpiredInvitation from "../ExpiredInvitation";
import { getQueryParams, sendUserVerificationEmail } from "../../../actions";
import InvalidInvitation from "../InvalidInvitation";
import SuccessPage from "../common/SuccessPage";
import EmailVerificationStep from "../common/EmailVerificationStep";
import CompleteAccountStep from "../common/CompleteAccountStep";

import EmailVerificationForm from "./EmailVerificationForm";
import CompleteAccountForm from "./CompleteAccountForm";

const UserRegistration: React.FC<{
  newUserRegistration: IUserRegistrationData;
}> = ({ newUserRegistration }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(getQueryParams(location));
    dispatch(sendUserVerificationEmail({ isTenantRegistrationFlow: false }));
  }, [dispatch]);
  if (newUserRegistration.error === ERegistrationError.expiredInvitation)
    return <ExpiredInvitation />;
  if (newUserRegistration.error === ERegistrationError.invalidInvitation)
    return <InvalidInvitation />;

  if (!newUserRegistration.emailVerified)
    return (
      <EmailVerificationStep
        isUser
        form={<EmailVerificationForm />}
        email={newUserRegistration.email}
      />
    );
  if (!newUserRegistration.accountCompleted)
    return <CompleteAccountStep form={<CompleteAccountForm />} isUser />;
  return (
    <SuccessPage isUser shouldLinksRender={false}>
      <p>You&apos;ve successfully completed your Brilliant Account!</p>
      <br />
      <p>
        Return to the Brilliant Mobile App to log in and start controlling your
        home.
      </p>
    </SuccessPage>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    newUserRegistration: state.newUserRegistration,
  };
};

export default connect(mapStateToProps)(UserRegistration);
