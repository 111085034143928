import * as Sentry from "@sentry/browser";
import { StatusCodes } from "http-status-codes";
import { AnyAction, Dispatch } from "redux";
import { AxiosError } from "axios";
import { Location } from "react-router-dom";

import EWebAPIError from "../../api/errors";
import {
  IAppState,
  IRegistrationAccountFormData,
  IVerificationFormData,
} from "../../interfaces/interfaces";
import { LOADER_START, LOADER_STOP } from "../types";
import {
  postSendVerificationText,
  postVerifyEmail,
  postVerifyPhone,
} from "../../api/registration";
import { postSendVerificationEmail } from "../../api/emails";
import { postAddUserToOrganization } from "../../api/organizations";
import { postAddMFAPhoneNumber, postCreateUser } from "../../api/users";
import {
  ADD_MFA_DEVICE_SUCCESS,
  ADD_PHONE_NUMBER,
  ADD_USER_TO_ORGANIZATION_SUCCESS,
  BIRTHDATE_FORMAT_INVALID,
  BIRTHDATE_INVALID,
  CLEAR_REGISTRATION_ERROR,
  CREATE_USER_SUCCESS,
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_SUCCESS,
  EXPIRED_INVITATION,
  FAMILY_NAME_INVALID,
  GET_QUERY_PARAMS,
  GIVEN_NAME_INVALID,
  PASSWORD_INVALID,
  PASSWORD_MISMATCH_REGISTRATION_ERROR,
  PHONE_VERIFICATION_SENT,
  PHONE_VERIFICATION_SUCCESS,
  TERMS_AND_CONDITIONS_ACCEPTED,
  USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_RATE_LIMITED,
} from "../constants";

export const acceptTermsAndConditions = (): AnyAction => {
  return {
    type: TERMS_AND_CONDITIONS_ACCEPTED,
  };
};

export const addPhoneNumber =
  (phoneNumber: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      payload: { phoneNumber },
      type: ADD_PHONE_NUMBER,
    });
  };

export const addUserToOrganization =
  () =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    try {
      const response = await postAddUserToOrganization(
        getState().newUser.organizationId,
        getState().newUser.userId,
        getState().newUser.jwt
      );
      dispatch({ type: LOADER_STOP });
      dispatch({
        payload: {
          jwt: response.data.token,
        },
        type: ADD_USER_TO_ORGANIZATION_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: LOADER_STOP });
      /* eslint-disable camelcase */
      const { response } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.CONFLICT &&
        (response.data.error_id ===
          EWebAPIError.USER_ALREADY_EXISTS_FOR_PROPERTY ||
          response.data.error_id === EWebAPIError.USER_IS_ALREADY_ADMIN)
      ) {
        dispatch({ type: USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION });
      } else
        dispatch({
          type: EXPIRED_INVITATION,
        });
    }
  };

export const addVerifiedMFAPhoneNumber =
  () =>
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    try {
      const response = await postAddMFAPhoneNumber(
        getState().newUser.userId,
        getState().newUser.phoneNumber,
        getState().newUser.jwt
      );
      dispatch({
        payload: {
          jwt: response.data.token,
        },
        type: ADD_MFA_DEVICE_SUCCESS,
      });

      // Do not invite a user to an organization until the phone number is verified.
      dispatch(addUserToOrganization());
    } catch (error) {
      dispatch({ type: LOADER_STOP });
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else {
        Sentry.captureMessage(
          `Route "users_mfa_devices_phone_number_new" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const createUser =
  (values: IRegistrationAccountFormData) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    const { birthdate, emailAddress, givenName, familyName, newPassword } =
      values;
    try {
      const response = await postCreateUser(
        birthdate,
        emailAddress,
        givenName,
        familyName,
        newPassword,
        getState().newUser.jwt
      );
      dispatch({ type: LOADER_STOP });
      dispatch({
        payload: {
          birthdate,
          familyName,
          givenName,
          jwt: response.data.token,
          userId: response.data.user_id,
        },
        type: CREATE_USER_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: LOADER_STOP });
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.PASSWORD_INVALID
      ) {
        dispatch({ type: PASSWORD_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.BIRTHDATE_FORMAT_INVALID
      ) {
        dispatch({ type: BIRTHDATE_FORMAT_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.BIRTHDATE_INVALID
      ) {
        dispatch({ type: BIRTHDATE_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.FAMILY_NAME_INVALID
      ) {
        dispatch({ type: FAMILY_NAME_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.GIVEN_NAME_INVALID
      ) {
        dispatch({ type: GIVEN_NAME_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else {
        Sentry.captureMessage(
          `Route "users_create" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const getQueryParams =
  (location: Location<any>) =>
  (dispatch: Dispatch): void => {
    const queryParams = new URLSearchParams(location.search);
    const payload = {
      email: (queryParams.get("email_address") || "").toLowerCase(),
      jwt: queryParams.get("token") || "",
      propertyId: queryParams.get("property_id") || "",
    };

    dispatch({
      payload,
      type: GET_QUERY_PARAMS,
    });
  };

export const sendVerificationEmail =
  ({ force = false }: { force?: boolean }) =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    try {
      const response = await postSendVerificationEmail(
        getState().newUser.email,
        getState().newUser.emailJWT,
        force
      );
      dispatch({
        payload: {
          jwt: response.data.token,
        },
        type: EMAIL_VERIFICATION_SENT,
      });
    } catch (error) {
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else {
        Sentry.captureMessage(
          `Route "emails_admin_portal_verify_email" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const sendVerificationText =
  () =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    try {
      const response = await postSendVerificationText(
        getState().newUser.email,
        getState().newUser.sendVerificationTextJWT,
        getState().newUser.phoneNumber
      );
      dispatch({
        payload: { jwt: response.data.token },
        type: PHONE_VERIFICATION_SENT,
      });
    } catch (error) {
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else {
        Sentry.captureMessage(
          `Route "registration_admin_verification_phone" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const verifyEmail =
  (values: IVerificationFormData) =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    try {
      const response = await postVerifyEmail(
        getState().newUser.email,
        getState().newUser.jwt,
        values.verificationCode
      );
      dispatch({ type: LOADER_STOP });
      dispatch({
        payload: {
          birthdate: response.data.birthdate,
          familyName: response.data.family_name,
          givenName: response.data.given_name,
          jwt: response.data.token,
          organizationId: response.data.organization_id,
          organizationName: response.data.organization_name,
          userId: response.data.user_id,
        },
        type: EMAIL_VERIFICATION_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: LOADER_STOP });
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({
          type: EXPIRED_INVITATION,
        });
      } else if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.WRONG_CODE
      ) {
        dispatch({
          type: VERIFICATION_CODE_FAILURE,
        });
      } else if (
        response &&
        response.status === StatusCodes.TOO_MANY_REQUESTS &&
        response.data.error_id === EWebAPIError.TOO_MANY_REQUESTS
      ) {
        dispatch({
          type: VERIFICATION_RATE_LIMITED,
        });
      } else {
        Sentry.captureMessage(
          `Route "registration_admin_verify_email" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const verifyPhone =
  (values: IVerificationFormData) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    try {
      const response = await postVerifyPhone(
        getState().newUser.email,
        getState().newUser.jwt,
        values.verificationCode
      );
      dispatch({
        payload: {
          jwt: response.data.token,
        },
        type: PHONE_VERIFICATION_SUCCESS,
      });
      dispatch(addVerifiedMFAPhoneNumber());
    } catch (error) {
      dispatch({ type: LOADER_STOP });
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({
          type: EXPIRED_INVITATION,
        });
      } else if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.WRONG_CODE
      ) {
        dispatch({
          type: VERIFICATION_CODE_FAILURE,
        });
      } else if (
        response &&
        response.status === StatusCodes.TOO_MANY_REQUESTS &&
        response.data.error_id === EWebAPIError.TOO_MANY_REQUESTS
      ) {
        dispatch({
          type: VERIFICATION_RATE_LIMITED,
        });
      } else {
        Sentry.captureMessage(
          `Route "registration_admin_verification_verify_phone" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const clearRegistrationError = (
  dispatch: Dispatch,
  getState: () => IAppState
): void => {
  if (getState().newUser.error) dispatch({ type: CLEAR_REGISTRATION_ERROR });
};

export const passwordMismatchError = (dispatch: Dispatch): void => {
  dispatch({ type: PASSWORD_MISMATCH_REGISTRATION_ERROR });
};
